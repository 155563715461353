    $('.today').on('click', function() {
        $('.today').addClass('active');
        $('.today div').fadeIn();
        $(this).closest('.dayNumberOs').hide();
    });
    $('.pastlink').on('click', function() {
        var day = $(this).data('day');
        console.log(day);
        $('#door_' + day).addClass('active');
        $('#door_' + day + ' div').fadeIn();
        $(this).closest('.dayNumberOs').hide();
    });
    $('.slider').slick({
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        dots: true,
        infinite: true,
        speed: 300,
        fade: true,
    });

    $('.fullscreen').on('click', function() {

        var divObj = $(this);
        //Use the specification method before using prefixed versions
        if (divObj.requestFullscreen) {
            divObj.requestFullscreen();
        }
        else if (divObj.msRequestFullscreen) {
            divObj.msRequestFullscreen();               
        }
        else if (divObj.mozRequestFullScreen) {
            divObj.mozRequestFullScreen();      
        }
        else if (divObj.webkitRequestFullscreen) {
            divObj.webkitRequestFullscreen();       
        } else {
            console.log("Fullscreen API is not supported");
        } 

    });
